import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// apicore
import { APICore } from '../../helpers/api/apiCore';

// helpers
import {
    addInv as addApi,addInvReturn_API,updInvReturn_API,addInvGrid as addInvApi,addInvSale,updInvSale,add_payReceipt,upd_payReceipt,addPOSSale,
    updPOSSale
} from '../../helpers';

// actions
import { salesApiResponseSuccess, salesApiResponseError } from './actions';

// constants
import { SalesActionTypes } from './constants';
import { addCedit_API, addDebit_API, updCedit_API, updDebit_API } from '../../helpers/api/sales';

type InvoiceData = {
    payload: {
        name: string;
        
    };
    type: string;
};
type SaleInvoiceData = {
    payload: {
       entrydate:string;customerid:string;sales_categoriesid:string;salesmanid:string;reference:string;notes:string;invoicetermsid:string|null;freight:string;trackNumber:string;shipped_via:string;totalDiscount:string;totalprice:string;AdvancePayment:string;subtotal:string;expense:string;invoicetotal:string;custfreight:boolean;custexpense:boolean;name:string;phone:string;address:string;tax:string;taxamount:string;invoicedetdata:Object
    };
    type: string;
};

type debitCredit_type = {
    payload: {id:string;customerid:string;entrydate:string;description:string;totalamount:string;voucherdetdata:[]};
    type: string;
};

type SalePOSData = {
    payload: {
      id:string; entrydate:string;sales_categoriesid:string;discount:string;totalprice:string;cashreceived:string;subtotal:string;invoicetotal:string|null;taxpercent:string|null;changeamount:string;taxamount:string|null;cashcounterid:string|null;invoicenumber:string;
      bankid:string;
      bankreceived:string;
      status:string;invoicedetdata:Object
    };
    type: string;
};
type InvoiceReturnData = {
    payload: {
       entrydate:string;invoicenumber:string;customerid:string;sales_categoriesid:string;salesmanid:string;reference:string;notes:string;invoicetermsid:string|null;freight:string;trackNumber:string;shipped_via:string;totalDiscount:string;totalprice:string;AdvancePayment:string;subtotal:string;expense:string;invoicetotal:string;custfreight:boolean;custexpense:boolean;name:string;phone:string;address:string;tax:string;taxamount:string;invoicedetdata:Object
    };
    type: string;
};
type UpdInvoiceReturnData = {
    payload: {
       id:string;entrydate:string;invoicenumber:string;customerid:string;sales_categoriesid:string;salesmanid:string;reference:string;notes:string;invoicetermsid:string|null;freight:string;trackNumber:string;shipped_via:string;totalDiscount:string;totalprice:string;AdvancePayment:string;subtotal:string;expense:string;invoicetotal:string;custfreight:boolean;custexpense:boolean;name:string;phone:string;address:string;tax:string;taxamount:string;invoicedetdata:Object
        
    };
    type: string;
};
type UpdSaleInvoiceData = {
    payload: {
       id:string;entrydate:string;customerid:string;sales_categoriesid:string;salesmanid:string;reference:string;notes:string;invoicetermsid:string|null;freight:string;trackNumber:string;shipped_via:string;totalDiscount:string;totalprice:string;AdvancePayment:string;subtotal:string;expense:string;invoicetotal:string;custfreight:boolean;custexpense:boolean;name:string;phone:string;address:string;tax:string;taxamount:string;invoicedetdata:Object
        
    };
    type: string;
};
type InvoiceGridData = {
    payload: {
        data: [];
        
    };
    type: Object;
};
type payment_receipt_types = {
    payload: {
        customerid:string;amount:string;paymethod:string;salesmanid:string;description:string;entrydate:string;checkNum:string;bank:string;duedate:string;deposit:string;reference:string
        };
    type: Object;
};
type updPayment_receipt_types = {
    payload: {
        id:string;customerid:string;amount:string;paymethod:string;salesmanid:string;description:string;entrydate:string;checkNum:string;bank:string;duedate:string;deposit:string;reference:string
        };
    type: Object;
};

const api = new APICore();
function* add({ payload: { name,} }: InvoiceData): SagaIterator {
    try {
        const response = yield call(addApi, { name,  });
        //console.log(response);
        const item = response.data;
        yield put(salesApiResponseSuccess(SalesActionTypes.ADD_Inv, item));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.ADD_Inv, error));
            }
}
function* addSaleInv({ payload: { entrydate,customerid,sales_categoriesid,salesmanid,reference,notes,invoicetermsid,freight,trackNumber,shipped_via,totalDiscount,totalprice,AdvancePayment,subtotal,expense,invoicetotal,custfreight,custexpense,name,phone,address,tax,taxamount,invoicedetdata} }: SaleInvoiceData): SagaIterator {
    try {
        const response = yield call(addInvSale, {entrydate,customerid,sales_categoriesid,salesmanid,reference,notes,invoicetermsid,freight,trackNumber,shipped_via,totalDiscount,totalprice,AdvancePayment,subtotal,expense,invoicetotal,custfreight,custexpense,name,phone,address,tax,taxamount,invoicedetdata });
        //console.log(response);
        const item = response.data;
        yield put(salesApiResponseSuccess(SalesActionTypes.ADD_SALEINVOICE, item));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.ADD_SALEINVOICE, error));
            }
}
function* updSaleInv({ payload: {id, entrydate,customerid,sales_categoriesid,salesmanid,reference,notes,invoicetermsid,freight,trackNumber,shipped_via,totalDiscount,totalprice,AdvancePayment,subtotal,expense,invoicetotal,custfreight,custexpense,name,phone,address,tax,taxamount,invoicedetdata} }: UpdSaleInvoiceData): SagaIterator {
    try {
        const response = yield call(updInvSale, { id,entrydate,customerid,sales_categoriesid,salesmanid,reference,notes,invoicetermsid,freight,trackNumber,shipped_via,totalDiscount,totalprice,AdvancePayment,subtotal,expense,invoicetotal,custfreight,custexpense,name,phone,address,tax,taxamount,invoicedetdata });
        //console.log(response);
        const item = response.data;
        yield put(salesApiResponseSuccess(SalesActionTypes.UPD_SALEINVOICE, item));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.UPD_SALEINVOICE, error));
            }
}
function* addPOSInv({ payload: { entrydate,sales_categoriesid,discount,totalprice,cashreceived,subtotal,invoicetotal,taxpercent,taxamount,changeamount,cashcounterid,invoicenumber,
    bankid,
    bankreceived,
    status,invoicedetdata } }: SalePOSData): SagaIterator {
    try {
        const response = yield call(addPOSSale, {entrydate,sales_categoriesid,discount,totalprice,cashreceived,subtotal,invoicetotal,taxpercent,taxamount,changeamount,cashcounterid,invoicenumber,
            bankid,
            bankreceived,
            status,invoicedetdata });
        //console.log(response);
        const item = response.data;
        yield put(salesApiResponseSuccess(SalesActionTypes.ADD_POS, item));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.ADD_POS, error));
            }
}
function* updPOSInv({ payload: {entrydate,sales_categoriesid,discount,totalprice,cashreceived,subtotal,invoicetotal,taxpercent,taxamount,cashcounterid,invoicedetdata } }: SalePOSData): SagaIterator {
    try {
        const response = yield call(updPOSSale, { entrydate,sales_categoriesid,discount,totalprice,cashreceived,subtotal,invoicetotal,taxpercent,taxamount,cashcounterid,invoicedetdata });
        //console.log(response);
        const item = response.data;
        yield put(salesApiResponseSuccess(SalesActionTypes.UPD_POS, item));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.UPD_POS, error));
            }
}
function* addInvReturn({ payload: { entrydate,invoicenumber,customerid,sales_categoriesid,salesmanid,reference,notes,invoicetermsid,freight,trackNumber,shipped_via,totalDiscount,totalprice,AdvancePayment,subtotal,expense,invoicetotal,custfreight,custexpense,name,phone,address,tax,taxamount,invoicedetdata} }: InvoiceReturnData): SagaIterator {
    try {
        const response = yield call(addInvReturn_API, {entrydate,invoicenumber,customerid,sales_categoriesid,salesmanid,reference,notes,invoicetermsid,freight,trackNumber,shipped_via,totalDiscount,totalprice,AdvancePayment,subtotal,expense,invoicetotal,custfreight,custexpense,name,phone,address,tax,taxamount,invoicedetdata });
        //console.log(response);
        const item = response.data;
        yield put(salesApiResponseSuccess(SalesActionTypes.ADD_INVOICERETURN, item));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.ADD_INVOICERETURN, error));
            }
}
function* updInvReturn({ payload: {id, entrydate,invoicenumber,customerid,sales_categoriesid,salesmanid,reference,notes,invoicetermsid,freight,trackNumber,shipped_via,totalDiscount,totalprice,AdvancePayment,subtotal,expense,invoicetotal,custfreight,custexpense,name,phone,address,tax,taxamount,invoicedetdata} }: UpdInvoiceReturnData): SagaIterator {
    try {
        const response = yield call(updInvReturn_API, { id,entrydate,invoicenumber,customerid,sales_categoriesid,salesmanid,reference,notes,invoicetermsid,freight,trackNumber,shipped_via,totalDiscount,totalprice,AdvancePayment,subtotal,expense,invoicetotal,custfreight,custexpense,name,phone,address,tax,taxamount,invoicedetdata });
        const item = response.data;
        yield put(salesApiResponseSuccess(SalesActionTypes.UPD_INVOICERETURN, item));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.UPD_INVOICERETURN, error));
            }
}
function* addGrid({ payload: {data} }: InvoiceGridData): SagaIterator {
    try {
        const response = yield call(addInvApi, { data  });
        //console.log(response);
        const item = response.data;
        // api.setLoggedInItem(item);
        // setItmmngtorization(item['token']);
        yield put(salesApiResponseSuccess(SalesActionTypes.ADD_INVGRID, item));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.ADD_INVGRID, error));
            }
}
function* addPayRec({ payload: {customerid,amount,paymethod,salesmanid,description,entrydate,checkNum,bank,duedate,deposit,reference} }: payment_receipt_types): SagaIterator {
    try {
        const response = yield call(add_payReceipt, {customerid,amount,paymethod,salesmanid,description,entrydate,checkNum,bank,duedate,deposit,reference});
        //console.log(response);
        const item = response.data;
        yield put(salesApiResponseSuccess(SalesActionTypes.PAYMENT_RECEIPT, item));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.PAYMENT_RECEIPT, error));
            }
}
function* updPayRec({ payload: {id,customerid,amount,paymethod,salesmanid,description,entrydate,checkNum,bank,duedate,deposit,reference} }: updPayment_receipt_types): SagaIterator {
    try {
        const response = yield call(upd_payReceipt, {id,customerid,amount,paymethod,salesmanid,description,entrydate,checkNum,bank,duedate,deposit,reference});
        //console.log(response);
        const item = response.data;
        yield put(salesApiResponseSuccess(SalesActionTypes.UPD_PAYMENT_RECEIPT, item));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.UPD_PAYMENT_RECEIPT, error));
            }
}

function* addCreditNote({ payload: {entrydate,customerid,description,totalamount,voucherdetdata} }: debitCredit_type): SagaIterator {
    try {
        const response = yield call(addCedit_API, {entrydate,customerid,description,totalamount,voucherdetdata});
        const item = response.data;
        yield put(salesApiResponseSuccess(SalesActionTypes.CREDIT_NOTE, item));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.CREDIT_NOTE, error));
            }
}
function* updCreditNote({ payload: {id,entrydate,customerid,description,totalamount,voucherdetdata} }: debitCredit_type): SagaIterator {
    try {
        const response = yield call(updCedit_API, {id,entrydate,customerid,description,totalamount,voucherdetdata});
        const item = response.data;
        yield put(salesApiResponseSuccess(SalesActionTypes.UPD_CREDIT_NOTE, item));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.UPD_CREDIT_NOTE, error));
            }
}
function* addDebitNote({ payload: {entrydate,customerid,description,totalamount,voucherdetdata} }: debitCredit_type): SagaIterator {
    try {
        const response = yield call(addDebit_API, {entrydate,customerid,description,totalamount,voucherdetdata});
        const item = response.data;
        yield put(salesApiResponseSuccess(SalesActionTypes.DEBIT_NOTE, item));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.DEBIT_NOTE, error));
            }
}
function* updDebitNote({ payload: {id,entrydate,customerid,description,totalamount,voucherdetdata} }: debitCredit_type): SagaIterator {
    try {
        const response = yield call(updDebit_API, {id,entrydate,customerid,description,totalamount,voucherdetdata});
        const item = response.data;
        yield put(salesApiResponseSuccess(SalesActionTypes.UPD_DEBIT, item));
    } catch (error: any) {
        yield put(salesApiResponseError(SalesActionTypes.UPD_DEBIT, error));
            }
}

export function* watchAdd(): any {
    yield takeEvery(SalesActionTypes.ADD_Inv, add);
     yield takeEvery(SalesActionTypes.CREDIT_NOTE, addCreditNote);
    yield takeEvery(SalesActionTypes.UPD_CREDIT_NOTE, updCreditNote);
    yield takeEvery(SalesActionTypes.DEBIT_NOTE, addDebitNote);
    yield takeEvery(SalesActionTypes.UPD_DEBIT, updDebitNote);
    yield takeEvery(SalesActionTypes.ADD_INVGRID, addGrid);
    yield takeEvery(SalesActionTypes.ADD_POS,addPOSInv);
    yield takeEvery(SalesActionTypes.UPD_POS,updPOSInv);
    yield takeEvery(SalesActionTypes.ADD_SALEINVOICE, addSaleInv);
    yield takeEvery(SalesActionTypes.UPD_SALEINVOICE, updSaleInv);
    yield takeEvery(SalesActionTypes.PAYMENT_RECEIPT, addPayRec);
    yield takeEvery(SalesActionTypes.UPD_PAYMENT_RECEIPT, updPayRec);
    yield takeEvery(SalesActionTypes.ADD_INVOICERETURN, addInvReturn);
    yield takeEvery(SalesActionTypes.UPD_INVOICERETURN, updInvReturn);
}


function* salesSaga() {
    yield all([fork(watchAdd)]);
}

export default salesSaga;
