import { APICore } from './apiCore';
import axios from 'axios';

const api = new APICore();

function add_financialYear(params) {
  //console.log('add user---');
    const baseUrl = '/add-financial-year';
    return api.create(`${baseUrl}`, params);
    
}
function add_userManagement(params) {
  //console.log('add user---');
    const baseUrl = '/register-user';
    return api.create(`${baseUrl}`, params);
    
}
function dashboardRight_API(params) {
  //console.log('add dashboard rights---');
    const baseUrl = `/update-dashboard-rights/${params.userid}`;
    return api.update(`${baseUrl}`, params);
    
}
function cashcounterRight_API(params) {
  //console.log('add cashcounter rights---');
    const baseUrl = `/update-cashcounter-rights/${params.userid}`;
    return api.update(`${baseUrl}`, params);
    
}
function UserRight_API(params) {
console.log(params)
  //console.log('add cashcounter rights---');
    const baseUrl = `/update-user-rights/${params.userid}`;
    return api.update(`${baseUrl}`, params);
    
}
function roles_API(params) {
  //console.log('add role---');
    const baseUrl = '/add-role';
    return api.create(`${baseUrl}`, params);
    
}
function updateUserApi(params) {
  //console.log('add update user---');
    const baseUrl = `update-user/${params.id}`;
    return api.update(`${baseUrl}`, params);
    
}
function manageLogin_API(params) {
  return api.create(`/add-loginpage-theme`, params);  
}
export {manageLogin_API,roles_API,add_financialYear,add_userManagement,updateUserApi,dashboardRight_API,cashcounterRight_API,UserRight_API};
