import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// constants
import { LayoutActionTypes } from './constants';
import { APICore } from '../../helpers/api/apiCore';
import { layoutResponseError, layoutResponseSuccess } from './actions';
import { SagaIterator } from 'redux-saga';
const api = new APICore();
type themeType = {
    payload: any;
    type: object;
};
/**
 * Toggle the class on body
 * @param {*} cssClass
 */
function manageBodyClass(cssClass: string, action = 'toggle') {
    switch (action) {
        case 'add':
            if (document.body) document.body.classList.add(cssClass);
            break;
        case 'remove':
            if (document.body) document.body.classList.remove(cssClass);
            break;
        default:
            if (document.body) document.body.classList.toggle(cssClass);
            break;
    }

    return true;
}

 function themeAPi(params) {
    const baseUrl = '/save-theme-customization';
    return api.create(`${baseUrl}`, params);
}
 function* addTheme({ payload: {layout,color,width,position,sidebarTheme,sidebarType,topbarTheme,footerType,shortcutbar} }:themeType): SagaIterator {
    try {
        const response = yield call(themeAPi, {layout,color,width,position,sidebarTheme,sidebarType,topbarTheme,footerType,shortcutbar});
        const item = response.data;
        yield put(layoutResponseSuccess(LayoutActionTypes.THEME, item));
    } catch (error: any) {
        
        yield put(layoutResponseError(LayoutActionTypes.THEME, error));
    }
}
function* showRightSidebar() {
    try {
        yield call(manageBodyClass, 'right-bar-enabled', 'add');
    } catch (error) {}
}

/**
 * Hides the rightsidebar
 */
function* hideRightSidebar() {
    try {
        yield call(manageBodyClass, 'right-bar-enabled', 'remove');
    } catch (error) {}
}
function* changeFooterType() {
    try {
        yield call(manageBodyClass, 'scroll-footer', 'toogle');
    } catch (error) {}
}
function* changeShortcutbar() {
    try {
        yield call(manageBodyClass, 'shortcut-bar', 'toogle');
    } catch (error) {}
}

export function* watchShowRightSidebar(): any {
    yield takeEvery(LayoutActionTypes.SHOW_RIGHT_SIDEBAR, showRightSidebar);
}
export function* watchAddTheme(): any {
    yield takeEvery(LayoutActionTypes.THEME, addTheme);
}
export function* watchHideRightSidebar(): any {
    yield takeEvery(LayoutActionTypes.HIDE_RIGHT_SIDEBAR, hideRightSidebar);
}
export function* watchFooterType(): any {
    yield takeEvery(LayoutActionTypes.TOGGLE_FOOTER, changeFooterType);
}
export function* watchShortcutBar(): any {
    yield takeEvery(LayoutActionTypes.TOGGLE_SHORTCUT, changeShortcutbar);
}

function* LayoutSaga(): any {
    yield all([fork(watchShowRightSidebar),fork(watchShortcutBar),fork(watchFooterType),  fork(watchHideRightSidebar),fork(watchAddTheme)]);
}

export default LayoutSaga;
