const MainLoader = ({useInfo}) => {
    
    return (
        <div style={{width:'100vw',height:"100vh",display:'flex',justifyContent:'center',alignItems:"center"}}>
       
            <div className="" id="" >
             <span className="logo-lg">
          {useInfo&& <img src={useInfo?.logopath} alt="" height="90" width="110" />}
        </span>
              
            </div>
      </div>
    );
};

export default MainLoader;